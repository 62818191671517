import React from "react"
import i18next from "i18next"
import * as ReactI18next from "react-i18next"
import { Helmet } from "react-helmet"

export const I18nLinksContext = React.createContext({})

export function wrapWithI18nProvider({ element, props }) {

  const i18n = i18next
    .createInstance({
      lng:
        props.pageContext.language !== undefined
          ? props.pageContext.language.code
          : undefined,
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources: props.pageContext.i18nResources,
    })
    .use(ReactI18next.initReactI18next)
  i18n.init()



  return (
    <ReactI18next.I18nextProvider i18n={i18n}>
      <I18nLinksContext.Provider
        value={
          props.pageContext && {
            alternatelinks: props.pageContext.alternatelinks,
            language: props.pageContext.language.code,
            home: props.pageContext.home,
            imageurl: props.pageContext.imageurl,
          }
        }
      >
        {
          <Helmet
            htmlAttributes={{
              lang:
                props.pageContext.language !== undefined
                  ? props.pageContext.language.code
                  : undefined,
            }}
          >
            {props.pageContext &&
              props.pageContext.alternatelinks &&
              props.pageContext.alternatelinks.map((link, key) => (
                <link
                  key={key}
                  rel="alternate"
                  hrefLang={link.language.code}
                  href={link.path}
                />
              ))}
          </Helmet>
        }
        {element}
      </I18nLinksContext.Provider>
    </ReactI18next.I18nextProvider>
  )
}
